import * as React from "react"
import Headers from "../components/headers"
import '../styles/global.css'
import image from '../images/nebulae.jpg';
import Helmetz from "../components/helmet";

// markup
const IndexPage = () => {
  return (
    <main>
      <Helmetz />
      <Headers />
      <img src={image} alt="Jack-O-Lantern Nebula" className="absolute object-cover w-full h-full"/>
            <section className='relative flex justify-center min-h-screen pt-20 px-8'>
                <h1 className="text-center xl:text-9xl lg:text-7xl text-6xl text-zinc-300 font-bold cursive leading-none lg:leading-snug mx-auto my-auto">
                    Hey there! I am Ferdinand. <br />
                    Nice to meet you!
                </h1>
            </section>
    </main>
  )
}

export default IndexPage
